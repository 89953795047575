.carouselContainer{
  @apply w-full relative;
  height: 100svh;
}

.sliderPagination {
  @apply container px-10 md:px-0 absolute bottom-10 left-0 right-0 mx-auto flex space-x-3;
  z-index: 51;
  & .sliderPaginationItem {
    @apply hidden md:block transition-all w-[16px] h-[16px] rounded-full border border-white cursor-pointer;
    &.active{
      @apply bg-white;
    }
  }
}