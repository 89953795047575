.comingsoon {

  @apply w-1/2 mx-auto h-screen flex items-center justify-center relative;

  .container {
    .image {
      mix-blend-mode: luminosity;
    }

    .logo {

      @apply absolute z-10;
      transform-origin: left;
      transform: rotate(-90deg) translateX(-60%) translateY(-30%);

      @media screen and (max-width: 768px) {
        @apply relative text-center mb-5;
        transform: none;
      }

      h1 {
        @apply font-bold m-0 p-0 inline-block;
        font-size: 8rem;
        line-height: 8rem;
        @media screen and (max-width: 768px) {
          @apply text-center;
          font-size: 4rem;
          line-height: 4rem;
        }
      }

      p {
        @apply m-0 font-light;
        font-size: 2rem;
        line-height: 2rem;
        @media screen and (max-width: 768px) {
          @apply text-center;
          font-size: 1rem;
          line-height: 1rem;
        }
      }
    }

    .instagram {
      @apply w-6 h-6 mt-2 mb-5;
      fill: #1f2937;
    }
  }


}