.titleCategory {
  @apply absolute top-32 left-3 md:left-5 text-3xl md:text-7xl font-bold md:block;
  transform-origin: left;
  transform: rotate(-90deg);
  z-index: 10;
}

.imgProject {
  @apply h-full mb-4 md:mb-0;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: auto;
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  will-change: opacity, visibility;
}