@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    min-height: -webkit-fill-available;
}

.is-visible {
    visibility: visible!important;
    opacity: 1!important;
}