//.headerTable {
//  @apply flex space-x-10 pb-5 mt-32;
//  border-bottom: 1px solid #707070;
//
//  div:nth-child(1) {
//    width: 100px
//  }
//
//  div:nth-child(2) {
//    width: 500px;
//  }
//
//  div:nth-child(3) {
//    width: 200px;
//  }
//
//  div:nth-child(4) {
//    width: 200px
//  }
//
//  div:nth-child(5) {
//    @apply flex-1
//  }
//
//  div:nth-child(6) {
//    width: 200px
//  }
//}

.rowData {
  @apply py-5 text-xs;
  div {
    @apply flex items-center;
  }

  div:nth-child(2) {
    @apply text-xl;
  }

  div:nth-child(6) {
    @apply text-3xl text-center;
  }
}

.tr {
  border-bottom: 1px solid #707070;
}