.headerFixed {
  @apply absolute left-0 bottom-0 right-0 top-0 z-50 container mx-auto pl-5 md:pl-0 h-[100px];
  mix-blend-mode: difference;
  background: transparent;
}

//.header {
//  @apply relative py-5 z-50 px-10 md:px-0;
//}

.containerHeader{
  @apply container fixed mx-auto top-5 flex items-center justify-between;
}

.logo {
  @apply w-[230px] cursor-pointer;
  color: #fff;
  position: relative;
  left: -4px;

  h1 {
    @apply text-6xl font-bold uppercase m-0 p-0;
    letter-spacing: 0.5rem; line-height: 3rem;
  }

  p {
    @apply font-light p-0 m-0 pl-1;
    letter-spacing: 0.05rem;
  }
}