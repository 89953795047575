.logo {
  @apply w-[350px] md:w-[1024px] cursor-pointer;
  color: #fff;
  position: relative;
  left: -4px;

  h1 {
    @apply text-6xl m-0 p-0;
    line-height: 4.5rem;

    @media screen and (max-width: 600px) {
      @apply text-xl m-0 p-0;
      line-height: 1.8rem;
      display: inline-block;
    }

    span, strong {
      min-width: 5px;
      max-width: 45px;
      text-align: left;
      overflow: hidden;
      position: relative;
      display: inline-block;
      transition: min-width, max-width, ease-in-out 350ms;
    }
  }

  p {
    @apply text-2xs md:text-base font-light p-0 m-0 md:pl-1 mt-[-15px] md:mt-[-20px]  ;
    opacity: 0;
    transition: opacity ease-in-out 500ms;
    transition-delay: 200ms;
    letter-spacing: 0.05rem;
  }
  &.shrinkLetters {
    h1 {
      strong{
        min-width: 55px;
        max-width: 90px;
        @media screen and (max-width: 600px) {
          min-width: 20px;
          max-width: 20px;
        }
      }
      :not(strong) {
        min-width: 0;
        max-width: 0;
      }
    }
    p{
      @media screen and (max-width: 600px) {
        @apply m-0 p-0;
        position: relative;
        top:-8px;
        display: inline-block;

      }
      opacity: 1;
    }
  }
}