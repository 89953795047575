.navContainer {
  @apply h-[100px] fixed bottom-16 left-0 right-0 mx-auto container z-50;
  mix-blend-mode: difference;
  .nav {
    @apply w-[250px] absolute right-0 pr-5 md:pr-0;
    right: -4px;

    .list li {
      @apply pt-2 text-right;
      a {
        @apply text-white text-xl md:text-3xl font-bold uppercase hover:opacity-30 transition-opacity duration-200 cursor-pointer;
        letter-spacing: 3px;

      }
    }
  }
}