.containerStudio {
  @apply container px-5 md:px-0 mx-auto flex items-end mt-0 md:mt-20;
}

.contentOffice {
  div:nth-child(1) {
    @apply text-xl md:text-4xl mt-20 mb-5 font-black;
    p:nth-child(1) {
      @apply text-4xl font-black md:px-2 md:py-1 mb-2 md:mb-0 inline-block;
      @media screen and (max-width: 600px) {
        background-color: transparent;
        color: black;
      }
      background-color: #000;
      color: white;
    }
  }

  div:nth-child(2) {
    @apply w-full text-xl font-black
  }
}

.rowData {
  @apply flex space-x-10 items-center py-1;
  div:nth-child(1) {
    @apply w-full flex-1;
    h2 {
      @apply text-4xl font-black px-2 py-1 inline-block;
      background-color: #b4c6f5;
    }
  }
}

.rowDetailData {
  @apply grid grid-cols-3 md:gap-10 gap-3 py-1;
  grid-template-columns: 150px 1fr 70px;

}

.rowDetailDataTitle {
  cursor: pointer;
  @apply md:text-2xl text-sm;
}

.rowDescription {
  & a {
    @apply relative inline-block mt-3;
  }
}

.contacts {
  @apply w-full text-xl font-black
}