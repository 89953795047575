.collapseContainer {
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  will-change: opacity, visibility;

  ul {
    list-style: circle;
    line-height: 2rem;
    height: auto;
    margin-bottom: 1rem;
    margin-left: 1rem;
    li {
        margin-left: 1rem;
    }
  }
}