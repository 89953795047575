.slide {
  img {
    pointer-events: none;
  };
  @apply w-full h-full bg-center bg-cover;
  .slideContent {
    @apply px-10 md:px-0 container h-full mx-auto relative;
    .title {
      @apply absolute bottom-0 md:bottom-20 left-7 md:left-4 text-white text-2xl md:text-5xl font-bold;
      transform-origin: left;
      transform: rotate(-90deg);
    }
  }
}